import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      fill="none"
      viewBox="0 0 30 24"
      className={className}
    >
      <path
        stroke="#fff"
        strokeWidth="4"
        d="M0 2h30M0 12h30M11 22h19"
      ></path>
    </svg>
  );
}

export default Icon;
