import React, { useState, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Link } from "gatsby";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";

import NavbarLink from "./components/NavbarLink";
import NavbarMobile from "./components/NavbarMobile";

import LogoIcon from "@components/svg/LogoIcon";
import Button from "@components/Button";
import MenuIcon from "@components/svg/MenuIcon";
import Offering from "@components/svg/Offering";
import Startup from "@components/svg/Startup";
import Saas from "@components/svg/Saas";
import Agencies from "@components/svg/Agencies";

import "./Navbar.styles.scss";

const Navbar = ({ alwayVisible = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    isScrolled();
    window.addEventListener("scroll", isScrolled);
    window.addEventListener("resize", _.throttle(isScrolled, 150));

    return () => {
      window.removeEventListener(
        "resize",
        _.throttle(checkIfMobile, 150),
        true,
      );
      window.removeEventListener(
        "scroll",
        _.throttle(isScrolled, 150),
        true,
      );
      window.removeEventListener(
        "resize",
        _.throttle(isScrolled, 150),
      );
      enableBodyScroll();
    };
  }, []);

  const handleMenuTrigger = () => {
    if (!isMenuActive) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
    setIsMenuActive(!isMenuActive);
  };

  const isScrolled = () => {
    const scrolled = window.pageYOffset;

    if (scrolled > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 1041);
  };
  return (
    <>
      <nav
        className={classNames("navbar", {
          "navbar--is-visible": isVisible || alwayVisible,
        })}
      >
        <div className="navbar__inner">
          <div className="navbar__logo">
            <Link to="/">
              <LogoIcon />
            </Link>
          </div>
          <div className="navbar__links">
            <NavbarLink to="/why">Why Hire us</NavbarLink>
            <NavbarLink to="/how">How do we differ</NavbarLink>
            <NavbarLink to="/testimonials">Testimonials</NavbarLink>
            <NavbarLink
              to="/services"
              aria-haspopup="true"
              className="navbar__has-dropdown"
            >
              Services
              <div className="navbar__dropdown-wrapper">
                <ul className="navbar__dropdown" aria-label="submenu">
                  <NavbarLink to="/offering" Icon={Offering}>
                    Our unique Offering
                  </NavbarLink>
                  <NavbarLink to="/startup" Icon={Startup}>
                    Tech Startups
                  </NavbarLink>
                  <NavbarLink to="/saas" Icon={Saas}>
                    SaaS or Enterprise Software
                  </NavbarLink>
                  <NavbarLink to="/agencies" Icon={Agencies}>
                    App-Development Agencies
                  </NavbarLink>
                </ul>
              </div>
            </NavbarLink>
            <NavbarLink to="/posts">Advice</NavbarLink>
            <NavbarLink to="/pricing">Pricing</NavbarLink>
            <NavbarLink to="/contact">
              <Button>Contact</Button>
            </NavbarLink>
          </div>
          <div className="navbar__menu" onClick={handleMenuTrigger}>
            <MenuIcon />
          </div>
        </div>
      </nav>
      {isMobile && (
        <NavbarMobile
          isVisible={isMenuActive}
          setIsMenuActive={setIsMenuActive}
        />
      )}
    </>
  );
};

export default Navbar;
