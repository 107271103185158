import React from "react";

function Icon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.2254 19.981C22.6126 20.3551 22.9939 20.7176 23.3811 21.0917C24.091 20.7234 24.402 20.776 24.9945 21.3782C26.4026 22.7987 27.7813 24.2485 29.2187 25.6398C30.1633 26.5576 30.1691 28.1827 29.5238 28.9953C28.544 30.2288 26.8954 30.3516 25.7631 29.2233C24.267 27.7385 22.7945 26.2302 21.316 24.7278C20.8877 24.2952 20.876 23.9094 21.2397 23.2313C20.8701 22.8806 20.4946 22.5298 20.1133 22.1674C16.6929 24.1257 13.2901 22.4012 11.9407 20.2908C10.4153 17.894 10.7673 14.6671 12.8207 12.7322C15.0091 10.6686 18.1479 10.4523 20.6061 12.2469C22.771 13.8312 23.8329 17.0172 22.2254 19.981ZM16.998 22.0154C19.7671 22.0037 21.9966 19.7823 22.0024 17.0289C22.0024 14.2755 19.7554 12.0365 16.998 12.0482C14.2288 12.0599 12.0052 14.293 12.0111 17.0464C12.017 19.7881 14.2699 22.0271 16.998 22.0154ZM21.9731 23.8977C22.0083 23.9737 22.0142 23.9913 22.0259 24.0088C23.5219 25.5287 25.0121 27.0603 26.5258 28.5686C26.9834 29.0245 27.5642 29.1181 28.1685 28.8375C29.1366 28.3932 29.3067 27.1773 28.5205 26.3705C27.6464 25.4761 26.7722 24.5875 25.8922 23.7048C25.282 23.0968 24.6484 22.5006 24.0793 21.9511C23.387 22.5883 22.6712 23.2488 21.9731 23.8977ZM21.6797 20.9105C21.4627 21.1151 21.2339 21.3314 21.0051 21.5477C21.3277 21.8634 21.6621 22.1849 21.9614 22.4713C22.1726 22.2667 22.4014 22.0446 22.6478 21.8108C22.3134 21.5009 21.979 21.1911 21.6797 20.9105Z" fill="#EB175C" />
            <path d="M4.97478 19.0801C4.97478 18.0279 4.96891 16.9756 4.97478 15.9234C4.98651 14.4502 5.43826 13.1407 6.4591 12.0476C7.74981 10.6621 9.35147 10.0132 11.2406 10.06C11.6748 10.0717 12.1089 10.1535 12.5372 10.2295C12.8833 10.2938 13.0593 10.5452 13.0007 10.8199C12.9361 11.1122 12.6663 11.2584 12.3201 11.1882C10.3019 10.7849 8.54771 11.2584 7.13966 12.79C6.32416 13.6786 5.98975 14.7542 5.98388 15.9409C5.97801 18.0279 5.97801 20.109 5.98388 22.196C5.98975 23.8679 6.67031 25.2183 8.00796 26.2354C9.1872 27.1357 10.3547 28.0477 11.5222 28.9538C11.9094 29.2519 11.9916 29.5208 11.7921 29.778C11.575 30.0645 11.2993 30.0469 10.9062 29.743C9.60962 28.7316 8.2837 27.7437 7.01645 26.6973C5.80787 25.6976 5.11558 24.3823 5.03344 22.8156C4.96891 21.5705 5.02171 20.3253 5.02171 19.0801C5.00411 19.0801 4.99238 19.0801 4.97478 19.0801Z" fill="#1B00BD" />
            <path d="M10.5035 12.0493C10.8262 12.0493 11.0257 12.2072 11.0667 12.4585C11.1078 12.7157 10.9259 12.9554 10.6384 13.0373C10.3216 13.125 9.99308 13.1893 9.69387 13.3237C8.6437 13.7914 8.01594 14.5923 8.00421 15.7614C7.98661 17.673 7.99247 19.5788 8.01594 21.4904C8.03354 23.0571 8.72584 24.3081 9.95788 25.2668C11.3366 26.3366 12.7271 27.3947 14.1058 28.4645C14.2466 28.5755 14.3932 28.7158 14.4578 28.8737C14.5458 29.0724 14.4695 29.2829 14.2759 29.4115C14.053 29.5576 13.83 29.5109 13.6247 29.3589C13.0439 28.9146 12.463 28.4703 11.8881 28.0202C10.9259 27.2719 9.94615 26.547 9.00745 25.7695C7.93967 24.8868 7.31778 23.7293 7.08311 22.3672C7.01857 21.9756 6.9951 21.5722 6.98924 21.1747C6.9775 19.4794 6.9951 17.7841 6.97164 16.0888C6.9423 13.844 8.36796 12.4527 10.3334 12.0669C10.4096 12.0552 10.48 12.0552 10.5035 12.0493Z" fill="#1B00BD" />
            <path d="M0.984777 16.2638C0.90264 14.5685 1.27225 12.8381 2.16989 11.2422C3.61314 8.66999 5.78389 7.02146 8.6528 6.34334C9.46243 6.15043 10.3131 6.14459 11.1462 6.08028C11.5393 6.05105 11.774 6.26735 11.7681 6.57133C11.7622 6.87532 11.5217 7.07992 11.1345 7.07408C9.21015 7.03315 7.44422 7.5359 5.87189 8.62323C3.61901 10.1841 2.28723 12.3178 2.03495 15.0654C1.96455 15.8487 1.97628 16.6379 1.98215 17.4271C1.98801 17.8304 1.77094 18.0351 1.4776 18.0467C1.16665 18.0584 0.97891 17.8129 0.97891 17.4212C0.97891 17.0822 0.984777 16.7373 0.984777 16.2638Z" fill="#1B00BD" />
            <path d="M14.8932 3.03384C14.8874 3.3846 14.5999 3.55413 14.2068 3.48982C13.3268 3.34367 12.4409 3.15076 11.555 3.09815C8.92661 2.94031 6.48598 3.61258 4.29763 5.05651C3.5056 5.57679 2.81918 6.25491 2.07995 6.86288C1.96261 6.96226 1.84527 7.07917 1.70447 7.12594C1.49326 7.19609 1.29379 7.12594 1.17058 6.92718C1.04151 6.71089 1.05324 6.48874 1.22925 6.30752C1.58713 5.94508 1.93914 5.58264 2.32636 5.25527C4.38564 3.51905 6.75586 2.47849 9.44876 2.16281C11.1384 1.96405 12.7988 2.08682 14.4415 2.51941C14.7642 2.60125 14.8991 2.75909 14.8932 3.03384Z" fill="#1B00BD" />
            <path d="M11.0106 4.05713C13.7622 4.10974 16.1148 4.91062 18.1741 6.52992C19.5528 7.61141 20.6147 8.95011 21.3715 10.5285C21.4302 10.6571 21.4889 10.8032 21.483 10.9377C21.4713 11.1715 21.3187 11.3294 21.0782 11.3703C20.82 11.4171 20.6499 11.2884 20.5267 11.078C20.1923 10.5109 19.8989 9.91467 19.5176 9.3827C17.9159 7.16712 15.7569 5.79919 13.0699 5.25553C12.0843 5.05677 11.0986 5.02169 10.1012 5.12107C9.7903 5.1503 9.53802 4.97493 9.49696 4.70017C9.45589 4.42542 9.63776 4.17989 9.95457 4.13897C10.3477 4.09805 10.7466 4.08051 11.0106 4.05713Z" fill="#1B00BD" />
            <path d="M3.02252 17.6837C3.02252 16.8477 2.96972 16.0059 3.03425 15.1758C3.16919 13.4221 3.93189 11.9431 5.16393 10.6979C5.45728 10.3998 5.78583 10.1367 6.1085 9.87363C6.38425 9.65148 6.66586 9.68071 6.8536 9.92039C7.04134 10.1542 7.00027 10.4348 6.71866 10.657C5.74476 11.4169 4.95859 12.3289 4.47164 13.4805C4.13723 14.2697 3.99056 15.094 3.98469 15.9475C3.97882 17.2803 3.98469 18.6132 3.97882 19.946C3.97882 20.3728 3.63268 20.6183 3.28066 20.4605C3.03425 20.3494 2.97559 20.1273 2.97559 19.8817C2.97559 19.151 2.97559 18.4203 2.97559 17.6895C2.99319 17.6837 3.01079 17.6837 3.02252 17.6837Z" fill="#1B00BD" />
            <path d="M11.0003 0C11.9683 0.128609 13.0419 0.263063 14.1097 0.415056C14.4559 0.467668 14.6436 0.71904 14.5908 0.993795C14.538 1.29778 14.2446 1.46146 13.8926 1.39131C12.7017 1.14579 11.4989 1.01718 10.2904 1.08148C8.33082 1.17502 6.45929 1.62515 4.69336 2.47864C4.56428 2.54295 4.42935 2.60725 4.29441 2.65986C3.97759 2.77678 3.73119 2.68909 3.60798 2.42018C3.49064 2.15712 3.59038 1.90575 3.88959 1.75376C5.27418 1.04641 6.73503 0.54951 8.26629 0.30983C9.13459 0.175376 10.0088 0.111071 11.0003 0Z" fill="#1B00BD" />
            <path d="M9.51138 19.3906C9.80472 19.3965 9.9866 19.6245 10.0101 19.9986C10.0394 20.4896 10.0277 20.9924 10.1215 21.4776C10.3562 22.7345 11.0602 23.7166 12.0576 24.5057C13.1136 25.3417 14.158 26.1835 15.214 27.0253C15.3959 27.1715 15.5719 27.341 15.4839 27.5865C15.4311 27.7385 15.2844 27.9139 15.1436 27.9606C15.0087 28.0074 14.7857 27.9548 14.6684 27.8613C13.4246 26.8733 12.1573 25.9087 10.9664 24.8623C9.47618 23.5529 8.91882 21.8342 9.01856 19.8875C9.02443 19.5777 9.23564 19.3789 9.51138 19.3906Z" fill="#1B00BD" />
            <path d="M16.2825 3.16211C16.3705 3.19718 16.4703 3.22641 16.57 3.27318C18.6 4.20852 20.2955 5.55306 21.6742 7.30682C21.9089 7.6108 21.9089 7.87971 21.6683 8.07847C21.4278 8.28308 21.1227 8.23046 20.888 7.92648C19.6149 6.30718 18.0485 5.06201 16.1769 4.19098C15.9364 4.07991 15.7369 3.92792 15.7721 3.62393C15.7956 3.39594 15.9364 3.26149 16.1535 3.19718C16.1887 3.18549 16.2297 3.1738 16.2825 3.16211Z" fill="#1B00BD" />
            <path d="M0.599909 12.8138C0.124691 12.8196 -0.0923836 12.4923 0.0366879 12.1006C0.464971 10.756 1.12793 9.5401 2.00796 8.43524C2.1605 8.24232 2.31891 8.0611 2.48318 7.87988C2.72372 7.60513 3.01707 7.55836 3.24587 7.75712C3.47468 7.95588 3.48642 8.25986 3.24001 8.52877C2.50078 9.35304 1.88476 10.2533 1.43887 11.2646C1.26873 11.6446 1.1338 12.0363 0.981257 12.4221C0.89912 12.6501 0.758315 12.8079 0.599909 12.8138Z" fill="#1B00BD" />
            <path d="M7.74571 4.55976C8.04493 4.55392 8.21507 4.68252 8.2796 4.92805C8.35 5.19111 8.23853 5.39572 7.99799 5.4951C7.59904 5.66463 7.17076 5.78154 6.78355 5.9803C6.17926 6.29013 5.59843 6.64088 5.00588 6.97994C4.94134 7.02087 4.87681 7.06763 4.81227 7.10855C4.52479 7.27224 4.24318 7.21962 4.09651 6.97994C3.94397 6.74026 4.00264 6.44797 4.27838 6.2726C4.8064 5.92769 5.33442 5.57109 5.89178 5.28465C6.41393 5.01574 6.98302 4.83452 7.53451 4.61237C7.61078 4.58314 7.69878 4.57145 7.74571 4.55976Z" fill="#1B00BD" />
            <path d="M16.7679 8.10148C16.7561 8.61591 16.3455 8.80298 15.9524 8.58668C15.3657 8.26516 14.779 7.94364 14.1806 7.65135C13.8931 7.51105 13.5763 7.4409 13.2712 7.34736C12.9133 7.23629 12.7608 7.01415 12.8312 6.70432C12.8957 6.42957 13.1597 6.28342 13.5059 6.37111C14.6089 6.65171 15.6238 7.12522 16.5567 7.77996C16.6681 7.87349 16.7268 8.04302 16.7679 8.10148Z" fill="#1B00BD" />
            <path d="M3.01455 23.0386C3.01455 22.6411 3.00868 22.3488 3.01455 22.0565C3.02628 21.7116 3.20229 21.5245 3.5015 21.5187C3.78898 21.5128 4.00018 21.7058 4.01779 22.039C4.03539 22.3897 4.02952 22.7405 4.02365 23.0912C4.01192 23.67 4.09405 24.2312 4.29353 24.7748C4.42847 25.149 4.32286 25.4062 4.00605 25.5056C3.71857 25.5991 3.47803 25.4588 3.34309 25.1022C3.07322 24.4065 2.99108 23.6758 3.01455 23.0386Z" fill="#1B00BD" />
            <path d="M12.0752 8.10889C12.5973 8.25503 13.1312 8.3661 13.6417 8.55902C14.0934 8.72855 14.5217 8.96823 14.95 9.19622C15.2433 9.35405 15.3196 9.62296 15.1846 9.88018C15.0556 10.1257 14.7564 10.2251 14.4806 10.0672C13.7003 9.60543 12.8731 9.30144 11.9813 9.13191C11.688 9.0793 11.5237 8.83377 11.5589 8.58825C11.5882 8.33687 11.8112 8.14981 12.0752 8.10889Z" fill="#1B00BD" />
            <path d="M8.02702 9.72764C7.76301 9.71594 7.6046 9.59903 7.54007 9.36519C7.46966 9.09628 7.57527 8.88583 7.80994 8.7923C8.3145 8.59354 8.83078 8.42401 9.34707 8.26033C9.55241 8.19602 9.76362 8.16679 9.98069 8.14341C10.2682 8.11418 10.5028 8.28371 10.5439 8.52924C10.5967 8.81568 10.4442 9.03783 10.1567 9.11967C9.50547 9.31258 8.86012 9.50549 8.20889 9.69841C8.14436 9.71594 8.07395 9.71594 8.02702 9.72764Z" fill="#1B00BD" />
            <path d="M18.3455 2.20837C18.3514 2.62342 18.0111 2.88649 17.7061 2.76372C17.0548 2.49481 16.4095 2.20252 15.77 1.90438C15.5177 1.78747 15.4414 1.53025 15.5353 1.29057C15.6292 1.05089 15.8638 0.898896 16.122 1.00412C16.8026 1.27888 17.4714 1.58871 18.1343 1.91023C18.2458 1.96284 18.2986 2.14406 18.3455 2.20837Z" fill="#1B00BD" />
            <path d="M16.9914 20.9986C14.8031 21.0044 13.0078 19.2507 13.0078 17.1052C13.0078 14.802 14.762 13.0248 17.0794 13.0541C19.3617 13.0891 20.9692 14.8897 20.9868 16.9591C21.0044 19.2214 19.2384 20.9927 16.9914 20.9986ZM19.9953 17.0643C19.9894 15.369 18.6694 14.042 16.9797 14.0479C15.3428 14.0479 13.9758 15.4333 14.0169 17.0994C14.0521 18.7304 15.3546 20.0399 17.097 20.0165C18.6752 19.9872 20.0011 18.6544 19.9953 17.0643Z" fill="#EB175C" />
        </svg>
    );
}

export default Icon;