import React from "react";

function Icon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.535 8.00041L9.7225 14.1879L7.955 15.9554L0 8.00041L7.955 0.0454102L9.7225 1.81291L3.535 8.00041Z"
        fill="white"
      />
    </svg>
  );
}

export default Icon;
