import React from "react";
import { ToastProvider } from "react-toast-notifications";

import SEO from "@utils/seo";
import Navbar from "@layouts/Navbar";
import Footer from "@components/Footer";

import "@styles/global.scss";
import "@styles/utils.scss";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

const Layout = ({
  children,
  alwayNavbarVisible = false,
  seo = {},
  isLanding = false,
}) => {
  return (
    <>
      <SEO {...seo} />
      <Navbar alwayVisible={alwayNavbarVisible} />
      <ToastProvider>
        <main className={`${!isLanding ? "global" : ""}`}>
          <div className="layout__inner">{children}</div>
        </main>
      </ToastProvider>
      <Footer />
    </>
  );
};

export default Layout;
