import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
      className={className}
    >
      <path
        stroke="#fff"
        strokeWidth="4"
        d="M2.394 23.607L23.607 2.393M2.394 2.393l21.213 21.214"
      ></path>
    </svg>
  );
}

export default Icon;
