import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import "./NavbarLink.styles.scss";

const NavbarLink = ({ children, className, Icon, hasIcon, ...rest }) => {
  return (
    <Link
      {...rest}
      className={classNames(
        "navbar-link",
        {"navbar-link--has-icon" : hasIcon || Icon},
        className
      )}
      activeClassName="navbar-link--active"
    >
      {Icon ? <span className="navbar-link__icon"><Icon /></span> : ""}
      {!Icon && hasIcon ? <span className="navbar-link__icon-placeholder"></span> : ""}
      {children}
    </Link>
  );
};

export default NavbarLink;
