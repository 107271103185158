import React, { useState } from "react";
import classNames from "classnames";
import LogoIcon from "@components/svg/LogoIcon";
import CloseIcon from "@components/svg/CloseIcon";
import Button from "@components/Button";
import NavbarLink from "../NavbarLink";
import Offering from "@components/svg/Offering";
import Startup from "@components/svg/Startup";
import Saas from "@components/svg/Saas";
import Agencies from "@components/svg/Agencies";
import Arrow from "@components/svg/NavArrow";

import "./NavbarMobile.styles.scss";

const NavbarMobile = ({ isVisible, setIsMenuActive }) => {

  const [isArrowActive, setIsArrowActive] = useState(false);

  const handleArrowClick = () => {
    setIsArrowActive(!isArrowActive);
  }

  return (
    <div
      className={classNames("navbar-mobile", {
        "navbar-mobile--active": isVisible,
      })}
    >
      <div className="navbar-mobile__inner">
        <div className="navbar-mobile__header">
          <div className="navbar-mobile__logo">
            <LogoIcon />
          </div>
          <div
            className="navbar-mobile__menu"
            onClick={() => setIsMenuActive(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="navbar-mobile__links">
          <NavbarLink to="/why">Why Hire us</NavbarLink>
          <NavbarLink to="/how">How do we differ</NavbarLink>
          <NavbarLink to="/testimonials">Testimonials</NavbarLink>
          <div>
            <NavbarLink to="/services" aria-haspopup="true" className="navbar-mobile__has-dropdown">
              Services
            </NavbarLink>
            <span className="navbar-mobile__arrow-wrapper" onClick={handleArrowClick}>
              <Arrow className={isArrowActive ? "navbar-mobile__arrow active" : "navbar-mobile__arrow"} />
            </span>
          </div>
          <ul className={isArrowActive ? "navbar-mobile__dropdown open" : "navbar-mobile__dropdown"} aria-label="submenu">
            <NavbarLink to="/offering" Icon={Offering}>Our unique Offering</NavbarLink>
            <NavbarLink to="/startup" Icon={Startup}>Tech Startups</NavbarLink>
            <NavbarLink to="/saas" Icon={Saas}>SaaS or Enterprise Software</NavbarLink>
            <NavbarLink to="/agencies" Icon={Agencies}>App-Development Agencies</NavbarLink>
          </ul>
          <NavbarLink to="/pricing">Pricing</NavbarLink>
          <NavbarLink to="/contact">
            <Button>Contact</Button>
          </NavbarLink>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
